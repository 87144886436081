/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { BsFillLightningFill } from 'react-icons/bs';
import { Collapse } from 'react-bootstrap';
import { toast } from 'react-toastify';
import moment from 'moment';
import DefaultLoader from '~/components/DefaultLoader';
import { Container } from './styles';
import api from '~/services/api';
import Separator from '~/components/Separator';
import Loja from '~/components/Loja';
import ToggleDefault from '~/components/ToggleDefault';
import InputDateTime from '~/components/Inputs/InputDateTime';
import InputSelect from '~/components/Inputs/InputSelect';
import InputDateRange from '~/components/Inputs/InputDateRange';
import FormDefault from '~/components/FormDefault';
import { InputMultiSelect } from '~/components/NovosInputs';
import { BuscaProduto } from '~/components/NovosInputs';
import useAuth from '~/hooks/useAuth';

import {
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
  TableHead,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import {
  IFormData,
  IOptions,
  IUsers,
  ParametroBalanca,
  TerminalPdv,
} from './types';
import { BuscaCheckList } from './components/BuscaProduto';
import { SelectType } from './components/BuscaProduto/protocols';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const Carga: React.FC = () => {
  const MySwal = withReactContent(Swal);
  const [loader, setLoader] = useState<boolean>(false);
  const [lojas, setLojas] = useState<any[]>([]);
  const [open, setOpen] = useState(false);
  const [cargaInvalid, setCargaInvalid] = useState(false);
  const [iniInicializado, setIniInicializado] = useState(false);
  const [usuarios, setUsuarios] = useState<IOptions[]>([]);
  const [tipoFiltro, setTipoFiltro] = useState(0);
  const { user } = useAuth();
  const [formData, setFormData] = useState<IFormData>({
    cod_loja: {
      value: undefined,
      isRequired: false,
      isInvalid: false,
    },
    flg_agendada: {
      value: false,
      isInvalid: false,
      isRequired: false,
    },
    dta_executar: {
      value: moment(),
      isInvalid: false,
      isRequired: false,
    },
    tipo_filtro: {
      value: { value: 0, label: 'Total' },
      isRequired: true,
      isInvalid: false,
    },
    dta_filtro_ini: {
      value: moment().subtract(3, 'days'),
      isInvalid: false,
      isRequired: false,
    },
    dta_filtro_fim: {
      value: moment(),
      isInvalid: false,
      isRequired: false,
    },
    cod_usuario_filtro: {
      value: undefined,
      isInvalid: false,
      isRequired: false,
    },
    tipo_carga: {
      value: {
        pdv: false,
        balanca: false,
        etiqueta: false,
      },
      isRequired: false,
      isInvalid: false,
    },
    flg_marcar_todos: {
      value: false,
      isInvalid: false,
      isRequired: false,
    },
  });
  const [formDisabled, setFormDisabled] = useState({
    dta_executar: true,
  });
  const defaultOptions = {
    tipo_filtro: [
      {
        label: 'Total',
        value: 0,
      },
      {
        label: 'Alterados em/por',
        value: 1,
      },
      {
        label: 'Produto',
        value: 2,
      },
      {
        label: 'Check-list',
        value: 3,
      },
    ],
    users: [
      {
        label: 'Marco',
        value: 0,
      },
      {
        label: 'Leonardo',
        value: 1,
      },
    ],
  };
  const [parametrosColumns, setParametrosColumns] = useState<any>([
    {
      id: 'loja',
      position: 0,
      headerName: 'Loja',
      visible: true,
    },
    {
      id: 'balanca',
      position: 1,
      headerName: 'Parâmetro Balança',
      visible: false,
    },
    {
      id: 'etiqueta',
      position: 2,
      headerName: 'Parâmetro Etiqueta',
      visible: false,
    },
  ]);
  const [parametros, setParametros] = useState<any>([]);
  const [parametrosEtiqueta, setParametrosEtiqueta] = useState<
    ParametroBalanca[]
  >([]);
  const [parametrosBalanca, setParametrosBalanca] = useState<
    ParametroBalanca[]
  >([]);
  const [terminalPdv, setTerminalPdv] = useState<TerminalPdv[]>([]);
  const primeiroInput = useRef<HTMLDivElement | null>(null);
  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onBlur',
  });

  useEffect(() => {
    if (formData.tipo_filtro.value?.value === 0) {
      const flgMarcartodos =
        formData.tipo_carga.value.pdv &&
        formData.tipo_carga.value.balanca &&
        formData.tipo_carga.value.etiqueta;

      setFormData({
        ...formData,
        flg_marcar_todos: {
          ...formData.flg_marcar_todos,
          value: flgMarcartodos,
        },
      });
    } else {
      const flgMarcartodos =
        formData.tipo_carga.value.pdv && formData.tipo_carga.value.etiqueta;
      setFormData({
        ...formData,
        flg_marcar_todos: {
          ...formData.flg_marcar_todos,
          value: flgMarcartodos,
        },
      });
    }

    if (
      !formData.tipo_carga.value.pdv === true ||
      formData.tipo_carga.value.balanca === true ||
      formData.tipo_carga.value.etiqueta === true
    ) {
      setCargaInvalid(false);
    }
  }, [
    formData.tipo_carga.value.pdv,
    formData.tipo_carga.value.balanca,
    formData.tipo_carga.value.etiqueta,
  ]);

  useEffect(() => {
    reset({
      busca_produto: {
        label: '',
        value: null,
      },
      busca_checklist: {
        label: '',
        value: null,
      },
    });
  }, [reset]);

  useEffect(() => {
    (async () => {
      if (lojas.length > 0) {
        const { data } = await api.post('/usuariosPorLojas', {
          idlojas: lojas,
        });
        if (data.success && data.data) {
          const options = data.data.map((users: IUsers) => ({
            label: users.des_usuario.toUpperCase(),
            value: users.cod_usuario,
          }));
          options.unshift({
            label: 'TODOS',
            value: 99999,
          });
          setUsuarios(options);
          setFormData({
            ...formData,
            cod_usuario_filtro: {
              ...formData.cod_usuario_filtro,
              value: {
                label: 'TODOS',
                value: 99999,
              },
            },
          });
          return;
        }
        setUsuarios([
          {
            label: 'TODOS',
            value: 99999,
          },
        ]);
        setFormData({
          ...formData,
          cod_usuario_filtro: {
            ...formData.cod_usuario_filtro,
            value: {
              label: 'TODOS',
              value: 99999,
            },
          },
        });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lojas]);

  /**
   * Validação
   */
  const validaInputsParaSubmit = useCallback(
    (inputs: IFormData): boolean => {
      for (let i = 0; i < Object.keys(inputs).length; i++) {
        if (
          (formData[Object.keys(inputs)[i] as keyof IFormData].isRequired &&
            formData[Object.keys(inputs)[i] as keyof IFormData].isInvalid) ||
          (formData[Object.keys(inputs)[i] as keyof IFormData].isRequired ===
            false &&
            formData[Object.keys(inputs)[i] as keyof IFormData].isInvalid)
        ) {
          return true;
        }
      }
      return false;
    },
    [formData],
  );
  const validaTiposCarga = useCallback(() => {
    if (
      formData.tipo_carga.value.pdv === false &&
      formData.tipo_carga.value.balanca === false &&
      formData.tipo_carga.value.etiqueta === false
    ) {
      return true;
    }
    return false;
  }, [
    formData.tipo_carga.value.balanca,
    formData.tipo_carga.value.etiqueta,
    formData.tipo_carga.value.pdv,
  ]);

  /**
   * Métodos da tela
   */
  const resetFormData = useCallback(() => {
    reset({
      busca_produto: {
        label: '',
        value: null,
      },
      busca_checklist: {
        label: '',
        value: null,
      },
    });
    setFormData({
      ...formData,
      cod_loja: {
        value: undefined,
        isRequired: false,
        isInvalid: false,
      },
      flg_agendada: {
        value: false,
        isInvalid: false,
        isRequired: false,
      },
      dta_executar: {
        value: moment(),
        isInvalid: false,
        isRequired: false,
      },
      tipo_filtro: {
        value: { value: 0, label: 'Total' },
        isRequired: true,
        isInvalid: false,
      },
      dta_filtro_ini: {
        value: moment().subtract(3, 'days'),
        isInvalid: false,
        isRequired: false,
      },
      dta_filtro_fim: {
        value: moment(),
        isInvalid: false,
        isRequired: false,
      },
      cod_usuario_filtro: {
        value: undefined,
        isInvalid: false,
        isRequired: false,
      },
      tipo_carga: {
        value: {
          pdv: false,
          balanca: false,
          etiqueta: false,
        },
        isRequired: false,
        isInvalid: false,
      },
      flg_marcar_todos: {
        value: false,
        isInvalid: false,
        isRequired: false,
      },
    });
    setFormDisabled({
      ...formDisabled,
      dta_executar: true,
    });
    setParametrosColumns([
      {
        id: 'loja',
        position: 0,
        headerName: 'Loja',
        visible: true,
      },
      {
        id: 'balanca',
        position: 1,
        headerName: 'Parâmetro Balança',
        visible: false,
      },
      {
        id: 'etiqueta',
        position: 2,
        headerName: 'Parâmetro Etiqueta',
        visible: false,
      },
    ]);
    reset();
    setOpen(false);
  }, [formData, formDisabled, reset]);

  const handleCargaAgendada = () => {
    setFormData({
      ...formData,
      flg_agendada: {
        ...formData.flg_agendada,
        value: !formData.flg_agendada.value,
      },
      dta_executar: {
        ...formData.dta_executar,
        value:
          !formData.flg_agendada.value === false
            ? moment()
            : formData.dta_executar.value,
        isRequired: !formData.flg_agendada.value === true,
        isInvalid: false,
      },
    });
    if (!formData.flg_agendada.value === false) {
      setFormDisabled({
        ...formDisabled,
        dta_executar: true,
      });
      return;
    }
    setFormDisabled({
      ...formDisabled,
      dta_executar: false,
    });
  };
  const handleDataExecutar = (val: string, isInvalid: boolean) => {
    setFormData({
      ...formData,
      dta_executar: {
        ...formData.dta_executar,
        value: val,
        isInvalid,
      },
    });
  };
  const handleFiltroCarga = async (
    val: { label: string; value: number },
    isInvalid: boolean,
  ) => {
    setOpen(false);
    setFormData({
      ...formData,
      tipo_carga: {
        ...formData.tipo_carga,
        value: {
          ...formData.tipo_carga.value,
          balanca: false,
          etiqueta: false,
          pdv: false,
        },
      },
      tipo_filtro: { ...formData.tipo_filtro, value: val, isInvalid },
      dta_filtro_ini: {
        ...formData.dta_filtro_ini,
        value:
          val.value === 1
            ? formData.dta_filtro_ini.value
            : moment().subtract(3, 'days'),
        isRequired: val.value === 1,
        isInvalid: val.value === 1,
      },
      dta_filtro_fim: {
        ...formData.dta_filtro_fim,
        value: val.value === 1 ? formData.dta_filtro_fim.value : moment(),
        isRequired: val.value === 1,
        isInvalid: val.value === 1,
      },
      cod_usuario_filtro: {
        ...formData.cod_usuario_filtro,
        value:
          val.value === 1
            ? formData.cod_usuario_filtro.value
            : {
                label: 'TODOS',
                value: 99999,
              },
        isRequired:
          val.value === 1 &&
          formData.cod_usuario_filtro.value?.value === undefined,
        isInvalid:
          val.value === 1 &&
          formData.cod_usuario_filtro.value?.value === undefined,
      },
    });
    setValue('busca_produto', {
      label: '',
      value: null,
    });
    setValue('busca_checklist', {
      label: '',
      value: null,
    });
    setOpen(val.value === 1 || val.value === 2 || val.value === 3);

    const paramsLojas = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const loja of lojas) {
      paramsLojas.push({
        cod_loja: loja,
        showBalanca: !formData.flg_marcar_todos.value,
        showEtiqueta: !formData.flg_marcar_todos.value,
      });

      setValue(`paramBalanca${loja}`, []);
      setValue(`paramEtiqueta${loja}`, []);
      setValue(`paramPdv${loja}`, []);

      setParametrosColumns((paramsColuns: any) =>
        paramsColuns.map((column: any) => {
          if (column.id === 'balanca') {
            column.visible = false;
          }
          if (column.id === 'etiqueta') {
            column.visible = false;
          }

          return column;
        }),
      );
    }
    setParametros(paramsLojas);
  };
  const handleDataIntervalo = (
    ini: string,
    fim: string,
    isInvalid: boolean,
  ) => {
    setFormData({
      ...formData,
      dta_filtro_ini: {
        ...formData.dta_filtro_ini,
        value: ini,
        isInvalid,
      },
      dta_filtro_fim: {
        ...formData.dta_filtro_fim,
        value: fim,
        isInvalid,
      },
    });
  };
  const handleUsuario = (
    val: { label: string; value: number },
    isInvalid: boolean,
  ) => {
    setFormData({
      ...formData,
      cod_usuario_filtro: {
        ...formData.cod_usuario_filtro,
        value: val,
        isInvalid,
      },
    });
  };

  const handleMarcarTodos = async () => {
    setFormData({
      ...formData,
      flg_marcar_todos: {
        ...formData.flg_marcar_todos,
        value: !formData.flg_marcar_todos.value,
      },
      tipo_carga: {
        ...formData.tipo_carga,
        value: {
          pdv: !formData.flg_marcar_todos.value !== false,
          balanca: formData.tipo_filtro.value?.value === 0,
          etiqueta: !formData.flg_marcar_todos.value !== false,
        },
      },
    });

    if (!formData.flg_marcar_todos.value) {
      setCargaInvalid(false);
    }

    const paramsLojas = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const loja of lojas) {
      if (!formData.flg_marcar_todos.value) {
        await getParametroBalanca(loja);
        await getParametroEtiqueta(loja);
        await getTerminalPdv(loja);
      }

      paramsLojas.push({
        cod_loja: loja,
        showBalanca: !formData.flg_marcar_todos.value,
        showEtiqueta: !formData.flg_marcar_todos.value,
      });

      setParametrosColumns((paramsColuns: any) =>
        paramsColuns.map((column: any) => {
          if (column.id === 'balanca') {
            column.visible =
              formData.tipo_filtro.value?.value === 0 &&
              !formData.flg_marcar_todos.value;
          }
          if (column.id === 'etiqueta') {
            column.visible = !formData.flg_marcar_todos.value;
          }
          return column;
        }),
      );
    }
    setParametros(paramsLojas);
  };

  const handleCargaPdv = () => {
    setFormData({
      ...formData,
      tipo_carga: {
        ...formData.tipo_carga,
        value: {
          ...formData.tipo_carga.value,
          pdv: !formData.tipo_carga.value.pdv,
        },
      },
    });
  };

  const getParametroBalanca = useCallback(async (loja: number) => {
    const { data } = await api.get(`/parametro-balanca`, {
      params: {
        loja,
      },
    });

    if (data.success && data.message !== 'Nenhum registro encontrado.') {
      const formatedOptions = data.data
        .filter((p: any) => p.flg_inativo !== true)
        .map((parametro: any) => ({
          label: `${parametro.des_interface}`,
          value: parametro.cod_parametro_balanca,
        }));
      setParametrosBalanca((oldValue) => [
        ...oldValue,
        {
          loja,
          parametros: formatedOptions,
        },
      ]);
    }
  }, []);

  const getParametroEtiqueta = useCallback(async (loja: number) => {
    const { data } = await api.get(`/parametro-etiqueta`, {
      params: {
        loja,
      },
    });
    if (data.success && data.message !== 'Nenhum registro encontrado.') {
      const formatedOptions = data.data
        .filter((p: any) => p.flg_inativo !== true)
        .map((parametro: any) => ({
          label: `${parametro.nome_pc} / ${parametro.des_parametro}`,
          value: parametro.cod_parametro_etiqueta,
        }));
      setParametrosEtiqueta((oldValue) => [
        ...oldValue,
        {
          loja,
          parametros: formatedOptions,
        },
      ]);
    }
  }, []);

  const getTerminalPdv = useCallback(async (loja: number) => {
    const { data } = await api.get(`/terminal-pdv`, {
      params: {
        loja,
      },
    });
    if (data.success && data.message !== 'Nenhum registro encontrado.') {
      setTerminalPdv((oldValue) => [
        ...oldValue,
        {
          loja,
          cod_terminal_pdv: data.data[0].cod_terminal_pdv,
        },
      ]);
    }
  }, []);

  const handleCargaBalanca = useCallback(async () => {
    setFormData({
      ...formData,
      tipo_carga: {
        ...formData.tipo_carga,
        value: {
          ...formData.tipo_carga.value,
          balanca: !formData.tipo_carga.value.balanca,
        },
      },
    });
    // Adiciona coluna de balança a tabela
    if (!formData.tipo_carga.value.balanca === true) {
      const arrayLojas: any = lojas;

      for (let i = 0; i < arrayLojas.length; i++) {
        const lojaExist = parametros.filter((parametro: any) => {
          return parametro.cod_loja === arrayLojas[i];
        });
        await getParametroBalanca(arrayLojas[i]);
        if (lojaExist.length > 0) {
          lojaExist[0].showBalanca = true;
          setParametros((params: any) => {
            return [
              ...params.filter((parametro: any) => {
                return parametro.cod_loja !== arrayLojas[i];
              }),
              lojaExist[0],
            ];
          });
          setParametrosColumns((paramsColuns: any) =>
            paramsColuns.map((column: any) => {
              if (column.id === 'balanca') column.visible = true;
              return column;
            }),
          );
        } else {
          const newValue = {
            cod_loja: arrayLojas[i],
            showBalanca: true,
          };
          setParametros((params: any) => [...params, newValue]);
          setParametrosColumns((paramsColuns: any) =>
            paramsColuns.map((column: any) => {
              if (column.id === 'balanca') column.visible = true;
              return column;
            }),
          );
        }
      }
    }
    // remove coluna de etiqueta da tabela
    if (!formData.tipo_carga.value.balanca === false) {
      if (formData.tipo_carga.value.etiqueta === false) {
        setParametros([]);
        setParametrosColumns([
          {
            id: 'loja',
            position: 0,
            headerName: 'Loja',
            visible: true,
          },
          {
            id: 'balanca',
            position: 1,
            headerName: 'Parâmetro Balança',
            visible: false,
          },
          {
            id: 'etiqueta',
            position: 2,
            headerName: 'Parâmetro Etiqueta',
            visible: false,
          },
        ]);
        setParametrosEtiqueta([]);
        setParametrosBalanca([]);
        reset();
        return;
      }
      setParametros((params: any) =>
        params.map((parametro: any) => {
          parametro.showBalanca = false;
          return parametro;
        }),
      );
      setParametrosColumns((paramsColuns: any) =>
        paramsColuns.map((column: any) => {
          if (column.id === 'balanca') column.visible = false;
          return column;
        }),
      );
      setParametrosBalanca([]);
    }
  }, [formData, getParametroBalanca, lojas, parametros, reset]);

  const handleCargaEtiqueta = useCallback(async () => {
    setFormData({
      ...formData,
      tipo_carga: {
        ...formData.tipo_carga,
        value: {
          ...formData.tipo_carga.value,
          etiqueta: !formData.tipo_carga.value.etiqueta,
        },
      },
    });
    // Adiciona coluna de etiqueta a tabela
    if (!formData.tipo_carga.value.etiqueta === true) {
      const arrayLojas: any = lojas;

      for (let i = 0; i < arrayLojas.length; i++) {
        const lojaExist = parametros.filter((parametro: any) => {
          return parametro.cod_loja === arrayLojas[i];
        });
        await getParametroEtiqueta(arrayLojas[i]);
        if (lojaExist.length > 0) {
          lojaExist[0].showEtiqueta = true;
          setParametros((params: any) => {
            return [
              ...params.filter((parametro: any) => {
                return parametro.cod_loja !== arrayLojas[i];
              }),
              lojaExist[0],
            ];
          });
          setParametrosColumns((paramsColuns: any) =>
            paramsColuns.map((column: any) => {
              if (column.id === 'etiqueta') column.visible = true;
              return column;
            }),
          );
        } else {
          const newValue = {
            cod_loja: arrayLojas[i],
            showEtiqueta: true,
          };
          setParametros((params: any) => [...params, newValue]);
          setParametrosColumns((paramsColuns: any) =>
            paramsColuns.map((column: any) => {
              if (column.id === 'etiqueta') column.visible = true;
              return column;
            }),
          );
        }
      }
    }
    // remove coluna de etiqueta da tabela
    if (!formData.tipo_carga.value.etiqueta === false) {
      if (formData.tipo_carga.value.balanca === false) {
        setParametros([]);
        setParametrosColumns([
          {
            id: 'loja',
            position: 0,
            headerName: 'Loja',
            visible: true,
          },
          {
            id: 'balanca',
            position: 1,
            headerName: 'Parâmetro Balança',
            visible: false,
          },
          {
            id: 'etiqueta',
            position: 2,
            headerName: 'Parâmetro Etiqueta',
            visible: false,
          },
        ]);
        setParametrosEtiqueta([]);
        setParametrosBalanca([]);
        reset();
        return;
      }
      setParametros((params: any) =>
        params.map((parametro: any) => {
          parametro.showEtiqueta = false;
          return parametro;
        }),
      );
      setParametrosColumns((paramsColuns: any) =>
        paramsColuns.map((column: any) => {
          if (column.id === 'etiqueta') column.visible = false;
          return column;
        }),
      );
      setParametrosEtiqueta([]);
    }
  }, [formData, getParametroEtiqueta, lojas, parametros, reset]);

  const handleLojas = useCallback(
    async (Lojas: number | number[]) => {
      const newLojas = Array.isArray(Lojas) ? Lojas : new Array(Lojas);
      setLojas(newLojas);
      const removida = lojas.filter((loja: number) => {
        return !newLojas.includes(loja);
      });
      if (lojas.length === removida.length) {
        const indexLoja = removida.indexOf(user.loja);
        removida.splice(indexLoja, 1);
      }
      const adicionada = newLojas.filter(
        (loja: number) => !lojas.includes(loja),
      );
      if (adicionada.length > 0) {
        await getTerminalPdv(adicionada[0]);
      }
      if (
        (newLojas && formData.tipo_carga.value.balanca) ||
        formData.tipo_carga.value.etiqueta
      ) {
        // Adiciona loja a tabela
        if (adicionada.length > 0) {
          // eslint-disable-next-line no-restricted-syntax
          for (const lojaAdd of adicionada) {
            // Adiciona balança
            if (
              !parametros.find(
                (parametro: any) => parametro.cod_loja === lojaAdd,
              )
            ) {
              await getTerminalPdv(lojaAdd);
              const Parametro = {
                cod_loja: lojaAdd,
                showEtiqueta: formData.tipo_carga.value.etiqueta,
                showBalanca: formData.tipo_carga.value.balanca,
              };
              setParametros((params: any) => [...params, Parametro]);
              setParametrosColumns((paramsColuns: any) =>
                paramsColuns.map((column: any) => {
                  if (column.id === 'balanca') {
                    column.visible = formData.tipo_carga.value.balanca;
                  }
                  if (column.id === 'etiqueta') {
                    column.visible = formData.tipo_carga.value.etiqueta;
                  }
                  return column;
                }),
              );
              if (formData.tipo_carga.value.etiqueta) {
                await getParametroEtiqueta(lojaAdd);
              }
              if (formData.tipo_carga.value.balanca) {
                await getParametroBalanca(lojaAdd);
              }
            }
          }
        }
        // remove loja a tabela
        if (removida.length > 0) {
          // eslint-disable-next-line no-restricted-syntax
          for (const lojaRemove of removida) {
            setParametros((params: any) =>
              params.filter(
                (parametro: any) => parametro.cod_loja !== lojaRemove,
              ),
            );
            setParametrosEtiqueta((params) =>
              params.filter((param) => param.loja !== lojaRemove),
            );
            setParametrosBalanca((params) =>
              params.filter((param) => param.loja !== lojaRemove),
            );
          }
        }
      }
    },
    [
      formData.tipo_carga.value.balanca,
      formData.tipo_carga.value.etiqueta,
      getParametroBalanca,
      getParametroEtiqueta,
      getTerminalPdv,
      lojas,
    ],
  );

  /**
   * Preparo e envio de dados para api
   */
  const handlePrepareData = useCallback(() => {
    return {
      flg_agendada: formData.flg_agendada.value,
      dta_executar: formData.flg_agendada.value
        ? moment(formData.dta_executar.value, 'DD/MM/YYYY HH:mm A').format(
            'YYYY-MM-DD HH:mm:ss',
          )
        : null,
      tipo_filtro: formData.tipo_filtro.value?.value,
      dta_filtro_ini:
        formData.tipo_filtro.value?.value === 1
          ? moment(formData.dta_filtro_ini.value, 'DD/MM/YYYY HH:mm A').format(
              'YYYY-MM-DD HH:mm:ss',
            )
          : null,
      dta_filtro_fim:
        formData.tipo_filtro.value?.value === 1
          ? moment(formData.dta_filtro_fim.value, 'DD/MM/YYYY HH:mm A').format(
              'YYYY-MM-DD HH:mm:ss',
            )
          : null,
      cod_usuario_filtro: formData.cod_usuario_filtro.value?.value,
      flg_pdv: formData.tipo_carga.value.pdv,
      cod_produto:
        formData.tipo_filtro.value?.value === 2
          ? getValues('busca_produto')?.value
          : null,
      cod_lista:
        formData.tipo_filtro.value?.value === 3
          ? getValues('busca_checklist').value
          : null,
    };
  }, [
    formData.cod_usuario_filtro.value?.value,
    formData.dta_executar.value,
    formData.dta_filtro_fim.value,
    formData.dta_filtro_ini.value,
    formData.flg_agendada,
    formData.tipo_filtro.value?.value,
    formData.tipo_carga.value.pdv,
    getValues,
  ]);

  const onSubmit = handleSubmit(async (dataForm) => {
    /**
     * Trabalhando parametros de balança e etiqueta
     * */
    const keyValue = Object.entries(dataForm);
    let hasFailedValidation = false;

    if (dataForm.busca_produto.value === null && tipoFiltro === 2) {
      setError('busca_produto', { type: 'focus' }, { shouldFocus: true });
      hasFailedValidation = true;
    }
    if (dataForm.busca_checklist.value === null && tipoFiltro === 3) {
      setError('busca_checklist', { type: 'focus' }, { shouldFocus: true });
      hasFailedValidation = true;
    }
    const allParams = [];
    for (let i = 0; i < keyValue.length; i++) {
      if (keyValue[i][1] === '') {
        const paramBalanca =
          keyValue[i][0].replaceAll(/[0-9]/g, '') === 'paramBalanca';
        const paramEtiqueta =
          keyValue[i][0].replaceAll(/[0-9]/g, '') === 'paramEtiqueta';
        const lojaNumberErro = Number(keyValue[i][0].replaceAll(/[^0-9]/g, ''));
        if (lojas.includes(lojaNumberErro)) {
          if (paramBalanca && formData.tipo_carga.value.balanca) {
            setError(keyValue[i][0], { type: 'focus' }, { shouldFocus: true });
            hasFailedValidation = true;
          }
          if (paramEtiqueta && formData.tipo_carga.value.etiqueta) {
            setError(keyValue[i][0], { type: 'focus' }, { shouldFocus: true });
            hasFailedValidation = true;
          }
        }
      } else {
        allParams.push(keyValue[i][1]);
      }
    }
    if (hasFailedValidation) {
      allParams.splice(0, allParams.length);
      return;
    }
    const paramsBalancas = allParams.filter(
      (param) => param.type === 'balanca',
    );
    const paramsBalanca = paramsBalancas.map((balanca) => {
      return {
        loja: balanca.loja,
        balanca,
      };
    });

    const paramsEtiquetas = allParams.filter(
      (param) => param.type === 'etiqueta',
    );
    const paramsEtiqueta = paramsEtiquetas.map((etiqueta) => {
      return {
        loja: etiqueta.loja,
        etiqueta,
      };
    });

    let existEtiqueta = false;
    // eslint-disable-next-line no-restricted-syntax
    for (const paramEtiqueta of paramsEtiqueta) {
      if (paramEtiqueta.etiqueta.length > 0) {
        existEtiqueta = true;
      }
    }

    let existBalanca = false;
    // eslint-disable-next-line no-restricted-syntax
    for (const paramBalanca of paramsBalanca) {
      if (paramBalanca.balanca.length > 0) {
        existBalanca = true;
      }
    }
    if (formData.tipo_carga.value.balanca && !existBalanca) {
      return toast.warning(
        'Carga de Balança deverá ser selecionado pelo menos um parâmetro de Balança',
      );
    }
    if (formData.tipo_carga.value.etiqueta && !existEtiqueta) {
      return toast.warning(
        'Carga de Etiqueta deverá ser selecionado pelo menos um parâmetro de Etiqueta',
      );
    }
    /**
     * Fim parametros de balança e etiqueta
     * */
    if (validaInputsParaSubmit(formData)) {
      if (validaTiposCarga()) {
        setCargaInvalid(true);
      }
      setIniInicializado(true);
      return false;
    }
    if (validaTiposCarga()) {
      setCargaInvalid(true);
      return;
    }
    if (!lojas) {
      toast.warn('Selecione ao menos uma loja!');
    }
    const tipos: string[] = [];
    if (formData.tipo_carga.value.pdv) {
      tipos.push('0');
    }
    if (formData.tipo_carga.value.balanca) {
      tipos.push('1');
    }
    if (formData.tipo_carga.value.etiqueta) {
      tipos.push('2');
    }
    if (
      formData.tipo_filtro.value?.value === 0 &&
      formData.tipo_carga.value.etiqueta
    ) {
      const { isConfirmed } = await MySwal.fire({
        title: 'Carga Total de Etiqueta!',
        text: 'Será enviado para a impressora um comando para que TODAS as etiquetas da loja sejam (re)impressas. Deseja Realmente Continuar?',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Confirmar',
        confirmButtonColor: '#28A745',
        cancelButtonColor: '#DC3545',
        icon: 'warning',
      });
      if (!isConfirmed) return;
    }

    setLoader(true);
    try {
      const { data } = await api.post('/carga', {
        formData: handlePrepareData(),
        tipos,
        lojas,
        paramsBalanca,
        paramsEtiqueta,
        terminalPdv,
      });
      if (data.success && data.message) {
        toast.success(data.message);
        setLoader(false);
        resetFormData();
        clearErrors();
        setTipoFiltro(0);
        setIniInicializado(false);
        return;
      }
      toast.error(data.message);
      setLoader(false);
      return;
    } catch (error: any) {
      setLoader(false);
      if (error.data !== undefined) {
        toast.error(error.data.message);
        return;
      }
      toast.error(String(error));
    }
  });

  useEffect(() => {
    if (primeiroInput.current) {
      const inputTipo: HTMLInputElement | null =
        primeiroInput.current.querySelector('[id="tipo"]');

      if (inputTipo) {
        inputTipo.focus();
        inputTipo.click();
      }
    }
  }, []);

  if (loader) {
    return <DefaultLoader />;
  }

  return (
    <Container>
      <FormDefault
        codTela={44}
        title="Carga"
        onSave={() => Promise.resolve()}
        onCancel={() => []}
        onClearFields={() => []}
        onNew={() => []}
        onDelete={() => []}
        onReturnSearch={() => []}
        isDelete={false}
        isClear={false}
        isSave
        isNew={false}
        isCancel={false}
      >
        <div className="row mb-2">
          <div className="col-md-12 mb-2">
            <Loja isMulti onChange={(value) => handleLojas(value)} />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-4" ref={primeiroInput}>
            <InputSelect
              options={defaultOptions.tipo_filtro}
              label="Tipo"
              id="tipo"
              value={formData.tipo_filtro.value}
              isRequired={formData.tipo_filtro.isRequired}
              setInvalid={formData.tipo_filtro.isInvalid}
              iniInicializado={iniInicializado}
              inLine
              noOptionsMessage="Nenhum registro encontrado"
              fontSize="0.8971428571428571em"
              placeholder="Selecione o tipo..."
              onChange={(newValue: any, isInvalid = true) => {
                setTipoFiltro(newValue.value);
                handleFiltroCarga(newValue, isInvalid);
              }}
            />
          </div>
          <div
            className="col-sm-12 col-md-4"
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <ToggleDefault
              labelText="Agendar Carga?"
              setChecked={formData.flg_agendada.value}
              onSwitch={() => handleCargaAgendada()}
              inLine
              fontSize="0.8971428571428571em"
            />
          </div>
          <div className="col-sm-12 col-md-4">
            <InputDateTime
              value={formData.dta_executar.value}
              labelText="Executar em:"
              inLine
              fontSize="0.8971428571428571em"
              isRequired={formData.dta_executar.isRequired}
              setInvalid={formData.dta_executar.isInvalid}
              iniInicializado={iniInicializado}
              isDisabled={formDisabled.dta_executar}
              onChange={(newValue: string, isInvalid = true) => {
                handleDataExecutar(newValue, isInvalid);
              }}
              format="DD/MM/YYYY HH:mm A"
            />
          </div>
        </div>
        {tipoFiltro === 1 && (
          <Collapse in={open}>
            <div
              className="row alterados--content"
              style={{
                padding: '0px 0px 0px 10px',
                marginTop: '-10px',
              }}
            >
              <Separator labelText="Alterados em/por" color="#8850bf" />
              <div className="col-sm-12 col-md-4">
                <InputDateRange
                  dataIni={formData.dta_filtro_ini.value}
                  dataFim={formData.dta_filtro_fim.value}
                  labelText="Intervalo"
                  isRequired={formData.dta_filtro_ini.isRequired}
                  setInvalid={formData.dta_filtro_ini.isInvalid}
                  iniInicializado={iniInicializado}
                  onChange={(ini: string, fim: string, isInvalid = true) => {
                    handleDataIntervalo(ini, fim, isInvalid);
                  }}
                />
              </div>
              <div className="col-sm-12 col-md-4">
                <InputSelect
                  options={usuarios}
                  label="Usuário"
                  menuPosition="fixed"
                  value={formData.cod_usuario_filtro.value}
                  isRequired={formData.cod_usuario_filtro.isRequired}
                  setInvalid={formData.cod_usuario_filtro.isInvalid}
                  iniInicializado={iniInicializado}
                  noOptionsMessage="Nenhum registro encontrado"
                  placeholder="Selecione o tipo"
                  onChange={(newValue: any, isInvalid = true) => {
                    handleUsuario(newValue, isInvalid);
                  }}
                />
              </div>
            </div>
          </Collapse>
        )}
        {tipoFiltro === 2 && (
          <Collapse in={open}>
            <div
              className="row alterados--content"
              style={{
                padding: '0px 0px 0px 10px',
                marginTop: '-10px',
              }}
            >
              <Separator labelText="Produto" color="#8850bf" />
              <div className="col-sm-12 col-md-12">
                <BuscaProduto
                  label=""
                  register={register}
                  control={control}
                  name="busca_produto"
                  placeholder="Código PLU, GTIN ou Descrição"
                  isError={!!errors.busca_produto}
                  buscaNasLojas={lojas}
                  customOptions={{
                    buscarPor: ['Produto'],
                    searchBeforeFilter: true,
                  }}
                  getProduct={(selected: any) => {
                    clearErrors('busca_produto');
                    setValue('busca_produto', {
                      label: `${selected[0].cod_produto} - ${selected[0].des_produto}`,
                      value: selected[0].cod_produto,
                    });
                  }}
                  setValue={setValue}
                />
              </div>
            </div>
          </Collapse>
        )}
        {tipoFiltro === 3 && (
          <Collapse in={open}>
            <div
              className="row alterados--content"
              style={{
                padding: '0px 0px 0px 10px',
                marginTop: '-10px',
              }}
            >
              <Separator labelText="Check-List" color="#8850bf" />
              <div className="col-sm-12 col-md-12">
                <BuscaCheckList
                  name="busca_checklist"
                  label=""
                  register={register}
                  placeholder="Digite para pesquisar"
                  isError={!!errors.busca_checklist}
                  control={control}
                  buscaNasLojas={lojas}
                  customOptions={{
                    buscarPor: ['Check-List'],
                    searchBeforeFilter: true,
                  }}
                  getProduct={(selected: SelectType) => {
                    clearErrors('busca_checklist');
                    setValue('busca_checklist', {
                      value: selected.value,
                      label: selected.label,
                    });
                  }}
                />
              </div>
            </div>
          </Collapse>
        )}
        <div className="row">
          <div className="col-12" style={{ marginBottom: '30px' }}>
            <Separator labelText="Gerar *" childrenWidth="130px">
              <ToggleDefault
                labelText="Marcar todos?"
                setChecked={formData.flg_marcar_todos.value}
                onSwitch={() => handleMarcarTodos()}
                inLine
                keepInLine
              />
            </Separator>
          </div>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
            }}
          >
            <div
              style={{
                width: '120px',
                margin: '10px',
              }}
            >
              <ToggleDefault
                labelText="PDV"
                setChecked={formData.tipo_carga.value.pdv}
                inLine
                size="large"
                onSwitch={() => handleCargaPdv()}
                isInvalid={cargaInvalid}
              />
            </div>
            {tipoFiltro === 0 && (
              <div
                style={{
                  width: '120px',
                  margin: '10px',
                }}
              >
                <ToggleDefault
                  labelText="BALANÇA"
                  setChecked={formData.tipo_carga.value.balanca}
                  inLine
                  size="large"
                  onSwitch={() => handleCargaBalanca()}
                  isInvalid={cargaInvalid}
                />
              </div>
            )}

            <div
              style={{
                width: '120px',
                margin: '10px',
              }}
            >
              <ToggleDefault
                labelText="ETIQUETAS"
                setChecked={formData.tipo_carga.value.etiqueta}
                inLine
                size="large"
                onSwitch={() => handleCargaEtiqueta()}
                isInvalid={cargaInvalid}
              />
            </div>
          </div>
        </div>
        {(formData.tipo_carga.value.balanca ||
          formData.tipo_carga.value.etiqueta) && (
          <div className="row">
            <div className="mt-2 mb-4">
              <TableContainer
                style={{
                  minHeight: '300px',
                  overflowX: 'visible',
                  position: 'relative',
                  zIndex: '0',
                }}
              >
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      {parametrosColumns &&
                        parametrosColumns
                          .filter((column: any) => column.visible === true)
                          .sort((a: any, b: any) => {
                            return a.position - b.position; // Ordena com base na propriedade "position"
                          })
                          .map((parametro: any) => {
                            return (
                              <TableCell key={parametro.id}>
                                {`${parametro.headerName}`}
                              </TableCell>
                            );
                          })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {parametros &&
                      parametros
                        .map((parametro: any, index: any) => {
                          const paramsEtiqueta = parametrosEtiqueta.filter(
                            (param) => param.loja === parametro.cod_loja,
                          );
                          const paramsBalanca = parametrosBalanca.filter(
                            (param) => param.loja === parametro.cod_loja,
                          );
                          return (
                            <TableRow key={parametro.cod_loja}>
                              <TableCell>{parametro.cod_loja}</TableCell>
                              {parametrosColumns.some(
                                (column: any) =>
                                  column.id === 'balanca' &&
                                  column.visible === true,
                              ) && (
                                <TableCell>
                                  <InputMultiSelect
                                    label=""
                                    placeholder="Selecione..."
                                    name={`paramBalanca${parametro.cod_loja}`}
                                    register={register}
                                    isError={
                                      !!errors[
                                        `paramBalanca${parametro.cod_loja}`
                                      ]
                                    }
                                    control={control}
                                    options={
                                      paramsBalanca.length > 0
                                        ? paramsBalanca[0].parametros
                                        : []
                                    }
                                    changeSelected={(selected: any) => {
                                      clearErrors();
                                      selected.loja = parametro.cod_loja;
                                      selected.type = 'balanca';
                                      setValue(
                                        `paramBalanca${parametro.cod_loja}`,
                                        selected,
                                      );
                                    }}
                                  />
                                </TableCell>
                              )}
                              {parametrosColumns.some(
                                (column: any) =>
                                  column.id === 'etiqueta' &&
                                  column.visible === true,
                              ) && (
                                <TableCell>
                                  <InputMultiSelect
                                    label=""
                                    placeholder="Selecione..."
                                    multiple
                                    name={`paramEtiqueta${parametro.cod_loja}`}
                                    register={register}
                                    isError={
                                      !!errors[
                                        `paramEtiqueta${parametro.cod_loja}`
                                      ]
                                    }
                                    control={control}
                                    options={
                                      paramsEtiqueta.length > 0
                                        ? paramsEtiqueta[0].parametros
                                        : []
                                    }
                                    changeSelected={(selected: any) => {
                                      clearErrors();
                                      selected.loja = parametro.cod_loja;
                                      selected.type = 'etiqueta';
                                      setValue(
                                        `paramEtiqueta${parametro.cod_loja}`,
                                        selected,
                                      );
                                    }}
                                  />
                                </TableCell>
                              )}
                            </TableRow>
                          );
                        })
                        .sort((a: any, b: any) => {
                          if (a.key < b.key) {
                            return -1;
                          }
                          if (a.key > b.key) {
                            return 1;
                          }
                          return 0;
                        })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        )}
        <div className="row">
          <div
            className="col-12"
            style={{
              display: 'flex',
              justifyContent: 'end',
            }}
          >
            <button
              type="button"
              style={{
                width: '300px',
                height: '40px',
                backgroundColor: '#8850bf',
                color: '#fff',
                border: 'none',
                borderRadius: '3px',
              }}
              onClick={() => onSubmit()}
            >
              <BsFillLightningFill fontSize="20px" color="#fff" />
              ENVIAR CARGA
            </button>
          </div>
        </div>
      </FormDefault>
    </Container>
  );
};

export default Carga;
